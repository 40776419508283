<template>
  <div>
    <v-row v-if="interviewSummary.length">
      <v-col cols="12" class="tw-my-8">
        <span class="interviewers">Interviews {{ interviewSummary.length }}</span>
      </v-col>
      <v-col cols="12" md="7" sm="12" class="scroll">
        <div v-for="(detail,i) in interviewSummary"  @click="goToReport(detail.id,detail.interview_rating)" :key="i" class="interview-card  tw-mb-8 ">
          <div class="tw-flex  tw-flex-col md:tw-flex-row tw-justify-between summary">
            <div>
              <h5>Interview {{ i + 1 }}</h5>
              <h3>{{detail.interview_rating==='no rating'?'No rating':detail.interview_rating +'%'+' ' +'Overall Rating'}} </h3>
              <div v-if="inviteAdmins(detail.invited_admins).length" class="tw-flex">
                <div class="tw-mt-6"  v-for="(admin,x) in inviteAdmins(detail.invited_admins)" :key="x">
                  <v-avatar class="avatar" size="30">{{admin.charAt(0)+''+ admin.charAt(1)}}</v-avatar>

                </div>
              </div>

            </div>
            <div class="tw-mt-4 md:tw-mt-0">
              <h3>{{ formatDate(detail.created) }} · {{ formatTime(detail.time) }}</h3>
            </div>
          </div>

        </div>
      </v-col>

    </v-row>
    <div v-else-if="loading">
      <Loader :loading="loading"/>
    </div>
    <div v-else class="tw-flex tw-my-8 tw-justify-center ">
      <small>No Interview activity for this candidate yet</small>
    </div>
  </div>
</template>

<script>
import {getInterviewSummary} from "@/services/api/APIService";
import moment from "moment";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";

export default {
  name: "InterviewActivities",
  components: {Loader},
  data() {
    return {
      interviewSummary: [],
      loading: false
    }
  },
  mounted() {
    this.loading = true
    getInterviewSummary(this.$route.query.email,this.$route.query.jobId)
        .then(res => {
          this.interviewSummary = res.data
          console.log(res.data)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
  },
  computed: {
    formatDate() {
      return function (date) {
        return moment(date).format('Do MMM YYYY');

      }
    },
    formatTime() {
      return function (time) {
        return moment(time, "HH:mm:ss").format("hh:mm A");

      }
    },
    inviteAdmins(){
      return function (details) {
        return Object.values(details)
      }
    }
  },
  methods:{
    goToReport(id,rating){
      this.$router.push({name:'InterviewReport',query:{email:this.$route.query.email,interview:id,rating:rating}})
    }
  }

}
</script>

<style scoped>
.interview-card {
  background: #FFFFFF;
  border: 1px solid #F7FCFF;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px;
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}

.avatar {
  background: #EEF8FF;
  border: 1.5px solid #FFFFFF;
  box-sizing: border-box;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  text-transform: uppercase;
  /* identical to box height, or 175% */

  display: flex;
  align-items: center;
  justify-content: center;
  color: #008EEF;
}

.summary h5 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1E323F;

}

.summary h3 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #475661;
}

.interviewers {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
}

.scroll {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

</style>
